import React from 'react'
import * as styles from '../../assets/styles.module.scss'

export default () => (
    <section id="Process" className={styles.Why}>
        <header>
            <h1>Why we do it</h1>

            <p>
                At Juvasoft, we love building products with pure craftmanship,
                passion, and dedication. We love partnering with companies – small, mid-size and large – 
                to help them create a better world. <strong>One product at a time</strong>.
            </p>

            <p>
                <strong>We understand that it takes all of us to deliver customer
                experiences that are accurate, beautiful, and effective.</strong>
            </p>
        </header>
    </section>
)