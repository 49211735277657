import React from 'react'
import * as styles from '../../assets/styles.module.scss'
import Discover from '../../assets/images/discover.png'
import Research from '../../assets/images/research.png'
import Build from '../../assets/images/build.png'
import Launch from '../../assets/images/launch.png'

export default () => (
    <section id="Process" className={styles.Process}>
        <header>
            <h1>How we do it: Process</h1>

            <p>
                Over the last 5 years, we've polished our methodology to 
                deliver high quality products on a timely banner and within budget.
                Our partners tell us that learning our process is just as valuable
                as the products we create.
            </p>
        </header>
        <section className={styles.Discover}>
            <div className={styles.Icon}>
                <img src={Discover} />
            </div>
            <h2>1. Discover & Workshop</h2>
            <p>
                Bring us a problem and we’ll work with you
                through our discovery process to understand
                users, goals, strategy, and a plan for success.
            </p>
        </section>
        <section className={styles.Research}>
            <div className={styles.Icon}>
                <img src={Research} />
            </div>
            <h2>2. Research & Prototype</h2>
            <p>
                We distill an idea to its core value and build
                a working proof of concept. Prototyping validates
                our hypotheses against your target audience.
            </p>
        </section>
        <section className={styles.Develop}>
            <div className={styles.Icon}>
                <img src={Build} />
            </div>
            <h2>3. Design, Build, Test</h2>
            <p>
                Working in tight cycles with lots of collaboration
                between design and development, we move quickly to
                iterate on ideas until they are ready to fly.
            </p>
        </section>
        <section className={styles.Launch}>
            <div className={styles.Icon}>
                <img src={Launch} />
            </div>
            <h2>4. Launch, Train, Support</h2>
            <p>
                We take great pleasure in seeing our clients soar!
                We love helping them build their own teams to continue
                growing the products we've created together.
            </p>
        </section>
    </section>
)