import * as React from 'react'
import * as styles from '../assets/styles.module.scss'
import { default as Header } from './common/header'
import { default as Footer } from './common/footer'
import { default as Heroshot } from './common/heroshot'

import { default as Tagline } from './homepage/Tagline'
import { default as Contact } from './homepage/Contact'

import { default as Why } from './about/Why'
import { default as Process } from './about/Process'
import { default as Services } from './about/Services'

export default class Page extends React.Component<{}, {}> {
    public render() {
        return (
            <section className={styles.Container}>
                <Header title="About Us | Juvasoft" />
                <Heroshot title={`We care about your business more than we care about ours`}
                subtitle={`
                    At Juvasoft, we take pride to create and build with true craftmanship. 
                    Every single project we contribute to, is taken care with the 
                    deepest passion and dedication.`} />
                <div className={styles.About}>
                    <Tagline />
                    <Why />
                    <Process />
                    <Services />
                    <Contact />
                </div>
                <Footer />
            </section>
        )
    }
}